import React from "react"

import { css } from "@emotion/core"

import { theme } from "../styles/theme"

const OrderedListItem = ({
  children,
  number,
  isBold = false,
  isRed = false,
}) => {
  return (
    <li
      css={[
        styles.item,
        css({
          fontWeight: isBold ? "bold" : "inherit",
          color: isRed ? theme.colours.red : "inherit",
        }),
      ]}
    >
      <span css={styles.number}>{number}.</span>
      {children}
    </li>
  )
}

const styles = {
  item: css({
    paddingLeft: "2rem",
  }),
  number: css({
    display: "inline-block",
    width: "3.8rem",
    marginLeft: "-3.8rem",
    textAlign: "right",
    paddingRight: " 0.5rem",
  }),
}

export default OrderedListItem
