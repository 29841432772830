import React from "react"
import SEO from "../components/seo"

import Layout from "../components/layout"
import CustomNavTitle from "../components/CustomNavTitle"
import OrderedListItem from "../components/OrderedListItem"

import { theme } from "../styles/theme"

const Disclaimer = ({ location }) => {
  return (
    <Layout>
      <SEO title="Disclaimer" />

      <CustomNavTitle title="Disclaimer" destination="/" location={location} />

      <article css={theme.components.row} className="theme">
        <section className="max-w-content">
          <h3 css={theme.components.title}>Disclaimer</h3>

          <ol className="custom-ordered-list">
            <OrderedListItem number="1">
              The Website is intended for the sole purpose to make users, within
              England, aware of key content from published guidelines on which
              the content is based and must be used in conjunction with these.
            </OrderedListItem>

            <OrderedListItem number="2">
              The Website must not be used as a substitute for professional or
              medical advice.
            </OrderedListItem>

            <OrderedListItem number="3">
              The Website, and the information contained in it, is provided to
              you "as is" with no guarantee of completeness, accuracy,
              timeliness or of the results obtained from the use of this
              information, and without warranty of any kind, express or implied,
              including but not limited to warranties of performance,
              merchantability and fitness for a particular purpose.
            </OrderedListItem>

            <OrderedListItem number="4">
              The Website functionality may be seriously affected by changes to
              the operating system of your device. You must not use the Website
              following an operating system change unless it is a version
              validated for use with the updated operating system.
            </OrderedListItem>

            <OrderedListItem number="5">
              Neither NHSBT, the developers, their employees or management shall be
              liable for any damages (including without limitation, direct,
              indirect, consequential or incidental damages) resulting from or
              in connection with:
            </OrderedListItem>

            <ol className="custom-ordered-list">
              <OrderedListItem number="1">
                any information, materials, qualifications or recommendations
                related to the Website;
              </OrderedListItem>
              <OrderedListItem number="2">
                matters beyond NHSBT's or the developer's control, including without
                limitation the incorrectness or incompleteness of input data and
                information provided on third party websites that may be linked
                to, directly or indirectly, by means of the Website;
              </OrderedListItem>
              <OrderedListItem number="3">
                any software or hardware used for electronic communications;
              </OrderedListItem>
              <OrderedListItem number="4">
                any other website on your device;
              </OrderedListItem>
              <OrderedListItem number="5">
                use of the Website with any incompatible operating system;
              </OrderedListItem>
              <OrderedListItem number="6">
                the disabling of the Website (for example, following updating
                your device's operating system);
              </OrderedListItem>
              <OrderedListItem number="7">
                cessation of Website function; or
              </OrderedListItem>
              <OrderedListItem number="8">
                the failure or delay in the delivery of electronic data, the
                interception or manipulation of electronic data by third parties,
                and transmission and the impact of software viruses.
              </OrderedListItem>
            </ol>
          </ol>

          <h3 css={theme.components.title}>Intellectual Property</h3>
          <ol className="custom-ordered-list">
            <OrderedListItem number="6">
              Unless otherwise stated, all rights, including copyrights and
              other intellectual property rights in the Website and all database
              rights in underlying databases and subsisting in information
              collected from you by NHSBT are the property of NHSBT.
            </OrderedListItem>
            <OrderedListItem number="7">
              Subject to data protection obligations, you may consult the
              Website and the information contained therein and record Website
              outputs (for example, using your device's print screen function).
              However, any other use of the Website or such information, for
              example the storage or reproduction of the Website or any part of
              it in any other medium or the creation of links, hyperlinks or
              deeplinks between the Website and any other medium, is prohibited
              without NHSBT's explicit written permission.
            </OrderedListItem>
          </ol>

          <h3 css={theme.components.title}>Privacy</h3>
          <ol className="custom-ordered-list">
            <OrderedListItem number="8">
              To create statistical reports about Website use, anonymous data
              generated from users, including you, are collected by means of a
              software development kit ("SDK") placed on your phone or device by
              Google Analytics' web server. The SDK contains an anonymous,
              unique sequence that is only accessible by the Google Analytics'
              web server. The SDK saves anonymous data about your Website use
              until your device is connected to the internet, when it sends it
              to the Google Analytics' web server. The server collates your data
              with equivalent data from other users. No personal data is sent,
              and the anonymous data will only be accessible to NHSBT and the
              developers of the Website.
            </OrderedListItem>
            <OrderedListItem number="9">
              The app uses Google Firebase Analytics SDK's, Google's privacy
              policy is available at:{" "}
              <a href="http://www.google.com/policies/privacy/">
                http://www.google.com/policies/privacy/
              </a>
              .
            </OrderedListItem>
            <OrderedListItem number="10">
              We will not be using or collecting any personal or professional
              identifiable information of Website users.
            </OrderedListItem>
          </ol>
        </section>
      </article>
    </Layout>
  )
}

export default Disclaimer
